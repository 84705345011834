<template>
  <div class="bg-bg_color text-text_color h-full">
    <div v-if="store" class="p-4">
      <div class="cursor-pointer mb-5" @click="goToRoute('/')">
        <NuxtImg
          provider="cacheIpx"
          preset="modified"
          loading="lazy"
          :src="store.icon"
          width="48"
          height="48"
          class="w-24 h-24 mx-auto rounded"
          :alt="store.name"
        />
      </div>

      <div class="border-b-2 bg-bg_color"></div>
      <div v-if="isMobileOrTablet" class="border-b-2 bg-bg_color">
        <div
          v-for="(item, index) in linkSettingsStore"
          :key="`drawer-menu-item-${index}`"
          class="item-drawer flex"
          :class="{
            'font-bold': item.route == route.path
          }"
          @click="goToRoute(item.route)"
        >
          <div class="w-12 mx-1"></div>
          <div class="font-medium">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div
        v-for="(item, index) in menu"
        :key="`drawer-menu-item-${index}`"
        class="item-drawer flex items-center"
        :class="{
          'font-bold':
            item.route.query.category_id.toString() == route.query.category_id
        }"
        @click="goToRoute(item.route)"
      >
        <div class="item-text-drawer">
          <div class="rounded-full p-1 border">
            <NuxtImg
              v-if="item.image"
              provider="cacheIpx"
              preset="modified"
              loading="lazy"
              width="48"
              height="48"
              class="p-0.5"
              :src="item.image"
              :alt="item.title"
            />
          </div>
        </div>
        <div class="w-3"></div>
        <div class="font-medium">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDomainCategoriesStore } from '~/store/domain_categories'
const { isMobileOrTablet } = useDevice()
const { t } = useI18n()
const { settingsStore } = useStoresSettings()
const { store } = useDomainState()
const { categoriesByCategoryStyle } = useDomainCategoriesStore()
const route = useRoute()
const router = useRouter()

const emit = defineEmits(['close'])

const menu = computed(() => {
  return categoriesByCategoryStyle.slice(0, 10).map((el) => ({
    title: el.name,
    image: el.image,
    route: {
      path: '/materials',
      query: { category_id: el.id }
    }
  }))
})
const linkSettingsStore = computed(() => {
  const linksTemp = [] as { title: string; route: string }[]

  settingsStore.value.forEach((el) => {
    linksTemp.push({
      route: `/about/${el.key}`,
      title: t(el.key)
    })
  })
  return linksTemp
})
function goToRoute(route: any) {
  emit('close')
  const localePath = useLocalePath()
  router.push(localePath(route))
}
</script>

<style scoped>
.item-drawer {
  @apply py-2  my-3 rounded-md relative transition-all duration-300 hover:bg-nav_color hover:text-text_alt_color hover:bg-opacity-80 cursor-pointer;
}
.item-drawer.active {
  @apply bg-nav_color text-text_alt_color bg-opacity-80;
}
.item-text-drawer {
  @apply bg-nav_color w-12 h-12 rounded-full border-4 border-bg_color;
}
</style>
